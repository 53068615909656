import 'isomorphic-fetch';

import Cookies from 'js-cookie';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
  addSeconds,
  formatISO,
  parseISO,
  isBefore,
  differenceInMilliseconds
} from 'date-fns';

import { AuthContext } from './context';
import { getIframeSrc, getSilentIframe, getParams } from './helpers';

const redirectUriKey = 'cb.auth.redirectUri';
const cbaStorageKey =
  'xfinityassistant-bushttps://oauth.xfinity.com/oauth/authorize';

const isDebug = () => Boolean(localStorage.getItem('cb.authDebug'));

const urlEncode = (object) => {
  return Object.entries(object)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');
};

export const PASSIVE_AUTH_STATES = {
  idle: 'idle',
  pending: 'pending',
  rejected: 'rejected',
  skipped: 'skipped',
  authenticated: 'authenticated',
  unauthenticated: 'unauthenticated'
};

const PASSIVE_AUTH_PRIORITY = {
  cima: 1,
  azuread: 2
};

export default function AuthProvider({ options, children }) {
  const namespace = options.authNamespace || 'default';
  const authProvider = options.authProvider || [];
  const passiveAuthTimeout =
    typeof options.passiveAuthTimeout === 'number'
      ? options.passiveAuthTimeout
      : 5000;

  const initialCodeState = authProvider.reduce(
    (state, provider) => ({
      ...state,
      [provider]: null
    }),
    {}
  );

  const getLocalStorageKey = useCallback(
    (key) => `cb.auth.${namespace}.${key}`,
    [namespace]
  );

  const [passiveAuthState, setPassiveAuthState] = useState(
    PASSIVE_AUTH_STATES.idle
  );
  const [passiveAuthCodeState, setPassiveAuthCodeState] =
    useState(initialCodeState);
  const passiveAuthCodeRef = useRef(initialCodeState);

  const [comAuthCore, setComAuthCore] = useState(
    localStorage.getItem(getLocalStorageKey('comAuthCore'))
  );
  const [token, setToken] = useState(null);
  const [expiration, setExpiration] = useState(null);
  const [error, setError] = useState(null);

  const onPassiveAuthComplete = useCallback(
    (e) => {
      const { data = {} } = e;
      const { source, payload = {} } = data;

      if (source !== 'bsd-passive-auth') {
        return null;
      }

      const { authenticated, code, provider } = payload;

      const newPendingAuthState = {
        ...passiveAuthCodeState,
        [provider]: code
      };

      if (!authenticated) {
        delete newPendingAuthState[provider];
      }

      setPassiveAuthCodeState(newPendingAuthState);
    },
    [passiveAuthCodeState]
  );

  const login = useCallback(() => {
    const encodedUri = window.location.href;
    const appReferrer = sessionStorage.getItem('app_referrer');
    const redirectUri = `${options.baseUrl}/authorize?client_id=${
      options.clientId
    }&response_type=code&redirect_uri=${encodeURIComponent(encodedUri)}${
      appReferrer ? `&app_referrer=${appReferrer}` : ''
    }`;

    // save current location as cb.auth.redirectUri
    localStorage.setItem(redirectUriKey, encodedUri);

    // redirect to auth service
    window.location = redirectUri;
  }, [options]);

  const logout = useCallback(() => {
    // update localStorage
    localStorage.removeItem(getLocalStorageKey('trackingId'));
    localStorage.removeItem(getLocalStorageKey('accessToken'));
    localStorage.removeItem(getLocalStorageKey('comAuthCore'));
    localStorage.removeItem(getLocalStorageKey('expiration'));
    localStorage.removeItem(cbaStorageKey);

    // update local state
    ReactDOM.unstable_batchedUpdates(() => {
      setError(null);
      setToken(null);
      setExpiration(null);
      setComAuthCore(null);
    });
  }, [setToken, setExpiration, setError, setComAuthCore, getLocalStorageKey]);

  const onAuthTimeout = useRef(null);

  const fetchToken = useCallback(
    async (code) => {
      try {
        if (isDebug()) {
          console.log(
            `Fetching token with code ${code} and redirect_uri ${localStorage.getItem(
              redirectUriKey
            )}`
          );
        }

        // make token call
        const result = await fetch(`${options.baseUrl}/token`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: urlEncode({
            /* eslint-disable camelcase */
            grant_type: 'authorization_code',
            client_id: options.clientId,
            code,
            redirect_uri: localStorage.getItem(redirectUriKey)
            /* eslint-enable camelcase */
          })
        });

        const response = await result.json();

        if (isDebug()) {
          console.log('Got token response');
          console.dir(response);
        }

        // sanity check response
        if (!response?.access_token) {
          throw new Error('Got token response with no token!');
        }

        const {
          access_token: accessToken,
          com_auth_core: comAuthCoreCookie,
          expires_in: expirationSeconds,
          id_token: idToken
        } = response;
        const redirectUri = localStorage.getItem(redirectUriKey);
        const expiresIn = addSeconds(new Date(), expirationSeconds);

        if (isDebug()) {
          console.log(`Setting token to ${accessToken}`);
        }

        // update localStorage
        localStorage.setItem(
          getLocalStorageKey('trackingId'),
          Cookies.get('cb_ucid')
        );
        localStorage.setItem(getLocalStorageKey('accessToken'), accessToken);
        localStorage.setItem(
          getLocalStorageKey('comAuthCore'),
          comAuthCoreCookie
        );
        localStorage.setItem(
          getLocalStorageKey('expiration'),
          formatISO(expiresIn)
        );
        localStorage.setItem(
          cbaStorageKey,
          JSON.stringify({
            accessToken,
            idToken
          })
        );

        if (options.requireAuthentication) {
          localStorage.removeItem(redirectUriKey);
        }

        // update local state
        ReactDOM.unstable_batchedUpdates(() => {
          setPassiveAuthState(PASSIVE_AUTH_STATES.authenticated);
          setToken(accessToken);
          setExpiration(expiresIn);
          setComAuthCore(comAuthCoreCookie);
          // clear any previous error
          setError(null);
        });

        if (options.requireAuthentication) {
          if (isDebug()) {
            console.log(`Finishing redirect to ${redirectUri}`);
          }

          // complete auth process by redirecting back to previously stored redirectUri
          window.history.replaceState('', '', redirectUri);
        }

        // set expiration timeout
        const expirationTimeout =
          differenceInMilliseconds(expiresIn, Date.now()) - 10000;

        if (isDebug()) {
          console.log(`Token expires in ~${expirationTimeout}ms`);
        }

        if (options.requireAuthentication) {
          setTimeout(login, expirationTimeout);
        } else {
          setTimeout(() => {
            // Reauthenticate silently
            setPassiveAuthCodeState(initialCodeState);
            setPassiveAuthState(PASSIVE_AUTH_STATES.idle);
          }, expirationTimeout);
        }
      } catch (err) {
        setError(err);
        setPassiveAuthState(PASSIVE_AUTH_STATES.rejected);

        if (isDebug()) {
          console.error(err);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      login,
      options.baseUrl,
      options.clientId,
      options.requireAuthentication,
      comAuthCore,
      getLocalStorageKey
    ]
  );

  const isPassiveAuthFetchReady =
    !Object.values(passiveAuthCodeState).includes(null);

  // Keep the ref value in sync with the state value
  useEffect(() => {
    passiveAuthCodeRef.current = passiveAuthCodeState;
  }, [passiveAuthCodeState]);

  useEffect(() => {
    if (options.requireAuthentication) {
      return null;
    }

    window.addEventListener('message', onPassiveAuthComplete);

    return () => {
      window.removeEventListener('message', onPassiveAuthComplete);
    };
  }, [onPassiveAuthComplete, options.requireAuthentication]);

  useEffect(() => {
    if (options.requireAuthentication || !isPassiveAuthFetchReady) {
      return null;
    }

    clearTimeout(onAuthTimeout.current);

    // Filter unauthenticated providers and select based on priority
    const selectedProvider = Object.keys(passiveAuthCodeState).sort(
      (curr, prev) => PASSIVE_AUTH_PRIORITY[curr] - PASSIVE_AUTH_PRIORITY[prev]
    )[0];

    if (selectedProvider) {
      fetchToken(passiveAuthCodeState[selectedProvider]);
    } else {
      // When all providers timeout (ie no codes in the state)
      setPassiveAuthState(PASSIVE_AUTH_STATES.unauthenticated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPassiveAuthFetchReady, options.requireAuthentication]);

  useEffect(() => {
    if (getParams('code') && localStorage.getItem(redirectUriKey)) {
      fetchToken(getParams('code'));
    } else if (options.requireAuthentication) {
      const rawToken = localStorage.getItem(getLocalStorageKey('accessToken'));
      const rawExpiration = parseISO(
        localStorage.getItem(getLocalStorageKey('expiration'))
      );
      const validToken =
        Boolean(rawToken) &&
        Cookies.get('cb_ucid') ===
          localStorage.getItem(getLocalStorageKey('trackingId')) &&
        isBefore(Date.now(), rawExpiration);

      if (!validToken) {
        login();
      } else {
        // set expiration timeout
        const expirationTimeout =
          differenceInMilliseconds(rawExpiration, Date.now()) - 10000;

        if (isDebug()) {
          console.log(`Token expires in ~${expirationTimeout}ms`);
        }

        setTimeout(login, expirationTimeout);

        // set token and expiration in context
        ReactDOM.unstable_batchedUpdates(() => {
          setToken(rawToken);
          setExpiration(rawExpiration);
        });
      }
    }
  }, [login, options, comAuthCore, fetchToken, getLocalStorageKey]);

  // Use silent authentication
  useEffect(() => {
    // Do not start passive auth if we are waiting for a code
    if (getParams('code') && localStorage.getItem(redirectUriKey)) {
      return null;
    }

    // Don't start passive auth if it's already started
    if (
      options.requireAuthentication ||
      passiveAuthState !== PASSIVE_AUTH_STATES.idle
    ) {
      return null;
    }

    // Don't start passive auth if the user-agent is a known bot
    const { userAgent } = window.navigator;

    if (userAgent.includes('Nutch') || userAgent.includes('Googlebot')) {
      setPassiveAuthState(PASSIVE_AUTH_STATES.skipped);
      return null;
    }

    setPassiveAuthState(PASSIVE_AUTH_STATES.pending);
    // Clear previous timeout for token refresh
    clearTimeout(onAuthTimeout.current);

    onAuthTimeout.current = setTimeout(() => {
      // Omit null values to fetch token when authentication times out
      const filteredCodeState = Object.keys(passiveAuthCodeRef.current).reduce(
        (acc, curr) => {
          // cleanup iframe that is still pending
          if (passiveAuthCodeRef.current[curr] === null) {
            document.getElementById(`${curr}-passive-auth`).remove();
          }

          return {
            ...acc,
            ...(passiveAuthCodeRef.current[curr] !== null
              ? { [curr]: passiveAuthCodeRef.current[curr] }
              : {})
          };
        },
        {}
      );

      setPassiveAuthCodeState(filteredCodeState);
    }, passiveAuthTimeout);

    const providers = Object.keys(passiveAuthCodeState);

    const appReferrer = sessionStorage.getItem('app_referrer');

    providers.forEach((provider) => {
      // Cleanup previously mounted iframe for token refresh
      const prevIframe = document.getElementById(`${provider}-passive-auth`);

      if (prevIframe) {
        prevIframe.remove();
      }

      // Get passive auth iframes
      const src = getIframeSrc({
        baseUrl: options.baseUrl,
        clientId: options.clientId,
        provider,
        appReferrer
      });
      const iframe = getSilentIframe(src, provider);

      // Append iframe to the document
      document.body.appendChild(iframe);
    });
  }, [
    passiveAuthCodeState,
    fetchToken,
    options.clientId,
    options.baseUrl,
    options.requireAuthentication,
    passiveAuthTimeout,
    passiveAuthState
  ]);

  if (options.requireAuthentication === null) {
    throw new Error('Must supply requireAuthentication boolean!');
  }

  return (
    <AuthContext.Provider
      value={{
        authState: passiveAuthState,
        login,
        logout,
        token,
        comAuthCore,
        error,
        expiration
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  options: PropTypes.object,
  children: PropTypes.node.isRequired
};
